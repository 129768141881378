import React, { Component, createRef } from "react";
import { Container, Row, Col, Button, InputGroup, FormControl, Spinner, Table } from "react-bootstrap";
import TopMenu from "../components/TopMenu";
import { callApi } from "../includes/Helper";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
import "chart.js";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
moment().format();

class Home extends Component {
  state = {
    showAddModal: false,
    showDownloadModal: false,
    downloadingReportId: 0,
    reportName: "",
    currentFile: null,
    isUploading: false,
    isDownloading: false,
    fileError: null,
    productName: "",
    fetchingProductData: false,
    products: undefined !== this.props && "history" in this.props ? this.props.history.location.state?.products : [],
  };

  constructor() {
    super();
    this.loadProducts = this.loadProducts.bind(this);
  }

  handleAsinSearchKeyPress(target) {
    if (target.charCode === 13) {
      this.loadProducts();
    }
  }

  componentDidMount() {
    console.log(`Back here`);
    this.setState({
      products: undefined !== this.props && "history" in this.props ? this.props.history.location.state?.products : [],
    });
  }

  loadProducts() {
    this.setState({ products: [], fetchingProductData: true });
    callApi("items/search", { title: this.state.productName }, (response) => {
      if ("status" in response && response.status)
        this.setState({ products: response.data, fetchingProductData: false });
      else this.setState({ fetchingProductData: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu adminData={this.state.admin_data} history={this.props.history} activeKey="/" />

        <Container fluid={true} className="page-dashboard">
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <div id="containerIG">
                <InputGroup id="ig_asin">
                  <FormControl
                    ref={(input) => {
                      this.productInput = input;
                    }}
                    value={this.state.productName}
                    onChange={(e) => {
                      this.setState({ productName: e.target.value });
                    }}
                    autoComplete="off"
                    type="text"
                    onKeyPress={(target) => {
                      if (target.charCode === 13) {
                        this.loadProducts();
                      }
                    }}
                    disabled={this.state.fetchingProductData}
                    id="productInputBox"
                    placeholder="Search by ASIN, UPC or Title"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Append className={"btn_asin_search"}>
                    <Button
                      onClick={() => this.loadProducts()}
                      className={
                        "button-search " +
                        (this.state.productName === "" &&
                        this.state.currentASIN !== "" &&
                        this.state.fetchingAsinData === false
                          ? "showAsinInButton"
                          : "")
                      }
                      disabled={this.state.productName === "" || this.state.fetchingProductData === true}
                    >
                      {this.state.fetchingAsinData === true ? (
                        <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <span>Search</span>
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              {this.state.products !== undefined && this.state.products.length > 0 && (
                <div>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Product Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.map((productName) => {
                        return (
                          <tr>
                            <td>
                              <Button
                                variant="link"
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: `product`,
                                    state: { productName: productName, products: this.state.products },
                                  });
                                }}
                              >
                                {productName}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Home;
