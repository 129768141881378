import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import auth from "../Auth";

class TopMenu extends Component {
  state = {
    domainName: window.location.hostname,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  render() {
    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Brand onClick={() => this.props.history.push("/home")}>
          <h3>{this.props.headerTitle !== undefined ? this.props.headerTitle : "Project Bourbon"}</h3>
        </Navbar.Brand>

        {/* <Nav className="mr-auto" activeKey={this.props.activeKey}>
          <Nav.Link href="/triggers">Triggers</Nav.Link>
          <Nav.Link href="/users">Users</Nav.Link>
          <Nav.Link href="/sounds">Sounds</Nav.Link>
          <Nav.Link href="/scans">Scans</Nav.Link>
          <Nav.Link href="/shipping-plans">Shipping Plans</Nav.Link>
          <Nav.Link href="/shipments">Shipments</Nav.Link>
          {"isReevaluate" in this.props && this.props.isReevaluate === true && (
            <Nav.Link href="/reevaluate-queue">Reevaluate-Queue</Nav.Link>
          )}
          {"access_level" in this.props.adminData && this.props.adminData.access_level === 0 && (
            <Nav.Link href="/invite">Invite Users</Nav.Link>
          )}
        </Nav> */}

        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={this.props.activeKey}>
            <Nav.Link onClick={() => this.props.history.push("/")}>Home</Nav.Link>
          </Nav>

          <Nav className="justify-content-end">
            {/* <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;
