import React, { Component } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";

class ModalDonate extends Component {
  state = {
    isOthersAmount: false,
    donationAmount: 100,
  };

  onChangeAudioFile = (e) => {
    console.log(e.target.files[0]);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showDonateModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Modal.Header closeButton>
            <h3>Support us</h3>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={7}>
                  <p>
                    If we saved you a boatload of time, consider tossing a few bucks into our tip jar (there’s a 90%
                    chance it gets spent on beer or golf!).
                  </p>

                  <h4>Select an amount</h4>

                  <div>
                    <Button
                      variant="success"
                      type="submit"
                      onClick={() => {
                        window.open("https://paypal.me/thebookflipper/15", "_blank");
                        this.props.proceedDownload();
                      }}
                    >
                      $15
                    </Button>

                    <Button
                      variant="success"
                      type="submit"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        window.open("https://paypal.me/thebookflipper/25", "_blank");
                        this.props.proceedDownload();
                      }}
                    >
                      $25
                    </Button>

                    <Button
                      variant="success"
                      type="submit"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        window.open("https://paypal.me/thebookflipper/35", "_blank");
                        this.props.proceedDownload();
                      }}
                    >
                      $35
                    </Button>

                    <Button
                      variant="success"
                      type="submit"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        window.open("https://paypal.me/thebookflipper/50", "_blank");
                        this.props.proceedDownload();
                      }}
                    >
                      $50
                    </Button>

                    <Button
                      variant="success"
                      type="submit"
                      style={{ marginLeft: "20px" }}
                      onClick={() => this.setState({ isOthersAmount: !this.state.isOthersAmount })}
                    >
                      OTHER
                    </Button>
                  </div>

                  {this.state.isOthersAmount && (
                    <div style={{ marginTop: "20px" }}>
                      <Form.Group controlId="formMinRank">
                        <Form.Label>Enter Tip Amount</Form.Label>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div>
                            <Form.Control
                              value={this.state.donationAmount}
                              required
                              type="number"
                              step="10"
                              name="tipAmount"
                              onChange={(e) => this.setState({ donationAmount: e.target.value })}
                              placeholder="E.g. 100"
                            />
                          </div>
                          <div style={{ paddingLeft: "10px" }}>
                            <Button
                              variant="primary"
                              onClick={() => {
                                window.open(`https://paypal.me/thebookflipper/${this.state.donationAmount}`, "_blank");
                                this.props.proceedDownload();
                              }}
                            >
                              PAY
                            </Button>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  )}
                </Col>
                <Col md={5}>
                  <img
                    style={{ width: `100%` }}
                    alt="logo"
                    className="logo-home"
                    src={require(`../assets/images/support-2.png`)}
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.props.proceedDownload();
              }}
            >
              Maybe Next time
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDonate;
