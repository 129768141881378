import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Route404 from "./pages/Route404";
import "./assets/style/theme_minimal.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./ProtectedRoute";
import Report from "./pages/Report";
import Signup from "./pages/Signup";
import Product from "./pages/Product";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {/* <Route exact path="/home" component={Login} /> */}
            {/* <Route exact path="/home" component={Home} /> */}
            <Route exact path="/product" component={Product} />
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/signup" component={Signup} />
            <ProtectedRoute exact path="/report/:id" component={Report} /> */}
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
